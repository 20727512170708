<template>
    <v-main>
        <vue-page-transition name="fade-in-right">
            <router-view/>
        </vue-page-transition>
    </v-main>
</template>

<script>

export default {
    name: 'BaseView',
}
</script>